export const listeProjets = [
	{
		id: 1,
		nom: "7 Lames la mer",
		slug: "7-lames-la-mer",
		description:
			"7 Lames la mer est un site d'information en ligne qui couvre l'actualité réunionnaise et internationale. Créé en 2013 en tant que projet autodidacte, le site a été développé sur le CMS Spip avant d'être migré en 2019 sur Wordpress pour améliorer sa performance et sa facilité d'utilisation. Grâce à son contenu de qualité et à une présentation claire et attrayante, 7 Lames la mer est devenu une référence pour les personnes à la recherche d'informations fiables et pertinentes sur l'océan Indien. Avec une large gamme de sujets traités, allant de la politique à la culture en passant par les événements sociaux, 7 Lames la mer est un site incontournable pour quiconque souhaite rester informé sur les développements dans la région et au-delà.",
		technologies: ["HTML", "CSS", "Spip", "Wordpress"],
		filter: ["Tout"],
		liens: [{ name: "Site Live", value: "https://7lameslamer.net/" }],
		image: {
			name: "001.jpg",
			alt: "",
		},
		client: {
			nom: "Nathalie LEGROS",
		},
		date: "Janvier 2013",
	},
	/*{
		id: 2,
		nom: "CV Walter White",
		slug: "cv-walter-white",
		description:
         "Dans le cadre du cours « Apprenez à créer votre site web avec HTML5 et CSS3 » chez OpenClassrooms, j'ai réalisé un projet de création d'une page web de CV pour le personnage fictif de la série « Breaking Bad », Walter White. J'ai utilisé les technologies HTML et CSS pour la réalisation de ce projet. Le résultat est une page web qui permet de mettre en valeur les compétences de Walter White. Ce projet m'a permis de consolider mes compétences en HTML et CSS et de découvrir de nouvelles astuces pour améliorer l'expérience utilisateur sur un site web."
		technologies: ["HTML", "CSS"],
		filter: ["Tout"],
		cahierdescharges: "TO ADD",
		github: "https://github.com/ArthurBlanc/CV_Walter_White",
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/CV_Walter_White/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/CV_Walter_White" },
		],
		image: {
			name: "002.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Septembre 2019",
	},*/
	{
		id: 3,
		nom: "Take",
		slug: "take",
		description:
			"Dans le cadre du cours « Découpez et intégrez une maquette » chez OpenClassrooms, j'ai réalisé l'intégration d'une page web à partir d'une maquette PSD. Cette maquette a été réalisée par Blaz Robar. Pour cela, j'ai utilisé les technologies HTML, CSS et Bootstrap. J'ai veillé à respecter scrupuleusement les éléments de la maquette tout en assurant un rendu responsive. Ce projet m'a permis de consolider mes compétences en intégration web et d'acquérir de nouvelles connaissances sur les outils de Bootstrap.",
		cahierdescharges:
			"Trouver une maquette, à partir de celle-ci, créer une structure de base en HTML et utiliser la grille Bootstrap pour organiser les éléments en colonnes. Ajouter du CSS pour personnaliser l'apparence et aller au-delà de ce que permet Bootstrap. Essayer de se rapprocher le plus possible de la maquette, même si nous comprendrons si la ressemblance n'est pas parfaite. Il faut donc partir d'un (ou plusieurs) documents au format PSD pour arriver à une page web finale. ",
		technologies: ["HTML", "CSS", "Bootstrap"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/Take/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/Take" },
		],
		image: {
			name: "003.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Septembre 2019",
	},
	/*{
		id: 4,
		nom: "FitActive",
		slug: "fitactive",
		description:
		"Dans le cadre du cours « Créez un site moderne et professionnel avec WordPress 5 » chez OpenClassrooms, j'ai réalisé la création d'un site web pour un club de sport nommé FitActive. Ce site web est destiné à un public de jeunes actifs souhaitant maintenir leur forme physique dans une ambiance conviviale. J'ai utilisé les technologies HTML, CSS et WordPress pour la réalisation de ce projet. J'ai veillé à concevoir un design moderne, dynamique et responsive, qui permet de mettre en avant les activités proposées par le club. Ce projet m'a permis d'acquérir des compétences en développement de sites WordPress et d'approfondir ma connaissance de l'écosystème WordPress et de ses plugins.",
         cahierdescharges: "TO ADD",
		technologies: ["HTML", "CSS", "Wordpress"],
		filter: ["Tout"],
		liens: [{ name: "Site Live", value: "https://abcoding.fr/projet/wordpress/FitActive/" }],
		image: {
			name: "004.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Septembre 2019",
	},
	{
		id: 5,
      nom: "Pokémon jQuery",
		slug: "pokemon-jquery",
		description: "Dans le cadre du cours « Introduction à jQuery » chez OpenClassrooms, j'ai réalisé une page web ludique sur l'univers de Pokémon. J'ai utilisé les technologies HTML, CSS, JavaScript et jQuery pour réaliser ce projet. J'ai veillé à rendre cette page dynamique et interactive en utilisant les fonctionnalités de jQuery, notamment pour animer les éléments de la page, pour ajouter des effets visuels et pour créer des interactions avec l'utilisateur. Le résultat final est une page web colorée et amusante qui plaira aux fans de l'univers de Pokémon. Ce projet m'a permis d'approfondir mes connaissances en jQuery et de découvrir de nouvelles astuces pour ajouter de l'interactivité à une page web.",
		cahierdescharges:
			"Réaliser une petite 'carte' web marrante en utilisant HTML, CSS et jQuery. En haut de page, il faut un 'paragraphe', suivi de 3 petits paragraphes, qui se dévoilent un par un lorsque l'on clique sur le précédent. Une fois que l'utilisateur a cliqué sur le dernier paragraphe, un gros effet sonore doit se faire entendre. Il est possible de faire en sorte que l'image d'arrière-plan change etc. ",
		technologies: ["HTML", "CSS", "JavaScript", "jQuery"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/Pokemon_jQuery/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/Pokemon_jQuery" },
		],
		image: {
			name: "005.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Novembre 2019",
	},
	{
		id: 6,
		nom: "ABCoding V1",
		slug: "abcoding-v1",
		description: "ABCoding V1 est la première version de mon site CV, réalisée à l'aide des technologies HTML et CSS. Ce projet m'a permis de présenter mes compétences et mon parcours professionnel. Ce projet m'a permis d'acquérir des compétences en développement web et de me familiariser avec les bonnes pratiques en matière de design et de conception de sites web.",
		technologies: ["HTML", "CSS"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://abcoding.fr/projet/HTML/ABCodingV1/index.html" },
		],
		image: {
			name: "006.jpg",
			alt: "",
		},
		client: {
			nom: "Arthur BLANC",
		},
		date: "Décembre 2019",
	},*/
	{
		id: 7,
		nom: "Mediefight",
		slug: "mediefight",
		description:
			"Mediefight est un mini-jeu de plateau au tour par tour dans un univers médiéval, réalisé en JavaScript. J'ai conçu ce jeu dans le cadre du cours « Activité - Créez un jeu de plateau tour par tour en JavaScript » chez OpenClassrooms. J'ai utilisé les technologies HTML, CSS, JavaScript et jQuery pour créer un jeu de plateau au tour par tour. Dans Mediefight, les joueurs s'affrontent en parcourant le plateau généré aléatoirement, ils peuvent ramasser différentes armes, chacune ayant des caractéristiques uniques. Ce projet m'a permis d'approfondir mes connaissances en JavaScript. Mediefight est un exemple concret de mes compétences en développement web et de ma passion pour les jeux vidéo.",
		cahierdescharges: "TO ADD",
		technologies: ["HTML", "CSS", "JavaScript", "jQuery"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/MedieFight/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/MedieFight" },
		],
		image: {
			name: "007.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Janvier 2020",
	},
	/*{
		id: 8,
		nom: "Among Words Translations",
		slug: "among-words-translations",
		description: "Among Words Translations est un site WordPress "One Page" réalisé en collaboration avec une traductrice en freelance. J'ai utilisé les technologies HTML, CSS et WordPress pour concevoir un site web moderne et professionnel. Le site présente les services de traduction de la cliente de manière claire et concise, tout en mettant en avant ses compétences et ses réalisations. J'ai également intégré des formulaires de contact pour permettre aux visiteurs de prendre rapidement contact avec la traductrice. Ce projet a été réalisé dans le cadre d'une collaboration étroite avec la cliente, afin de répondre au mieux à ses attentes et à ses besoins. Among Words Translations est un exemple de mon savoir-faire en matière de conception de sites web WordPress, ainsi que de ma capacité à travailler en équipe et à communiquer efficacement avec mes clients.",
		technologies: ["HTML", "CSS", "Wordpress"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://amongwords.com" },
		],
		image: {
			name: "008.jpg",
			alt: "",
		},
		client: {
			nom: "Julie GRONDIN",
		},
		date: "Février 2020",
	},*/
	{
		id: 9,
		nom: "Reservia",
		slug: "reservia",
		description:
			"Reservia est un projet réalisé dans le cadre de ma formation de Développeur Web chez OpenClassrooms. J'ai travaillé à partir d'une maquette fournie pour intégrer la page d'accueil d'une agence de voyage fictive en utilisant les technologies HTML et CSS. J'ai également travaillé sur l'optimisation des images pour améliorer les temps de chargement du site. J'ai également pris en compte les principes de conception adaptative pour assurer une expérience utilisateur optimale sur tous les types d'appareils (mobiles, tablettes, ordinateurs de bureau). Reservia est un exemple de mon expertise en matière d'intégration web.",
		technologies: ["HTML", "CSS"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/Reservia/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/Reservia" },
		],
		image: {
			name: "009.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Octobre 2020",
	},
	{
		id: 10,
		nom: "ohmyfood",
		slug: "ohmyfood",
		description: "Intégration de la version mobile d'un site de foodtech avec des animations. Projet réalisé dans le cadre de la formation « Développeur Web » chez OpenClassrooms.",
		description:
			"ohmyfood est un projet que j'ai réalisé dans le cadre de ma formation de Développeur Web chez OpenClassrooms. À partir d'une maquette fournie, j'ai travaillé sur l'intégration de la version mobile d'un site de foodtech avec des animations CSS. Ce projet m'a permis de mettre en pratique mes compétences en HTML et CSS, tout en utilisant Sass pour faciliter la gestion du code CSS. Le résultat final est un site web moderne qui met en avant les plats proposés par l'entreprise. Grâce à l'utilisation de Sass, j'ai pu gagner en productivité et en maintenabilité du code CSS, ce qui m'a permis de mieux organiser mon code et de le rendre plus facilement évolutif.",
		technologies: ["HTML", "CSS", "Sass"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/ohmyfood/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/ohmyfood" },
		],
		image: {
			name: "010.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Novembre 2020",
	},
	{
		id: 11,
		slug: "la-chouette-agence",
		nom: "La chouette agence",
		description:
			"La chouette agence est un projet que j'ai réalisé dans le cadre de ma formation de Développeur Web chez OpenClassrooms. J'ai travaillé sur l'optimisation d'un site web existant pour une agence de web design. Mon objectif était d'améliorer le référencement naturel du site en mettant en place des techniques de SEO. J'ai également apporté des améliorations au niveau de l'accessibilité et de la qualité du code HTML et CSS. Le résultat final est un site web plus performant et mieux positionné dans les résultats de recherche, ce qui permet à l'agence de mieux se faire connaître et d'attirer plus de clients.",
		technologies: ["HTML", "CSS", "SEO"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/LaChouetteAgence/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/LaChouetteAgence" },
		],
		image: {
			name: "011.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Novembre 2020",
	},
	{
		id: 12,
		nom: "Orinoco",
		slug: "orinoco",
		description:
			"Orinoco est un projet réalisé dans le cadre de ma formation de Développeur Web chez OpenClassrooms. J'ai travaillé sur la création d'un MVP pour un site e-commerce en utilisant JavaScript ainsi qu'une API. J'ai également utilisé Bootstrap pour faciliter le développement et rendre le site responsive. Ce projet m'a permis de mettre en pratique mes compétences en HTML, CSS et en JavaScript, ainsi que de découvrir l'utilisation d'une API pour récupérer et afficher des données dynamiques sur un site web. Le résultat final est un site e-commerce fonctionnel qui permet à l'utilisateur de parcourir les produits, d'ajouter des articles à son panier et de passer commande. Grâce à l'utilisation de Bootstrap, j'ai pu gagner en rapidité de développement et en maintenabilité du code, ce qui m'a permis de me concentrer sur l'implémentation de fonctionnalités clés.",
		technologies: ["HTML", "CSS", "JavaScript", "API", "Bootstrap"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/Orinoco/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/Orinoco" },
		],
		image: {
			name: "012.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Avril 2021",
	},
	{
		id: 13,
		nom: "So Pekocko",
		slug: "so-pekocko",
		description:
			"So Pekocko est un projet réalisé dans le cadre de ma formation de Développeur Web chez OpenClassrooms. J'ai créé une API pour une application d'avis gastronomiques en utilisant NodeJS et MongoDB. J'ai également utilisé le framework Express pour faciliter la gestion des routes et des requêtes. Le résultat final est une API qui permet aux utilisateurs de créer des comptes, de se connecter et de publier des avis sur des sauces gastronomiques. Ce projet m'a permis de mettre en pratique mes compétences en JavaScript et de travailler avec des technologies telles que NodeJS et MongoDB.",
		technologies: ["JavaScript", "API", "NodeJS", "Express", "MongoDB"],
		filter: ["Tout"],
		liens: [{ name: "GitHub", value: "https://github.com/ArthurBlanc/SoPekocko" }],
		image: {
			name: "013.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Mai 2021",
	},
	{
		id: 14,
		nom: "Groupomania",
		slug: "groupomania",
		description:
			"Groupomania est un projet réalisé dans le cadre de ma formation de Développeur Web chez OpenClassrooms. J'ai travaillé sur la création d'un réseau social d'entreprise en utilisant VueJS pour la partie frontend et NodeJS/Express pour la partie backend. Pour faciliter la mise en page et la gestion du responsive design, j'ai également utilisé Bootstrap. La base de données a été réalisée en SQL. Le résultat final est une application web qui permet aux employés d'une entreprise de partager des publications, de commenter et de liker les publications d'autres employés. Ce projet m'a permis de mettre en pratique mes compétences en JavaScript, VueJS, NodeJS/Express et en SQL pour la gestion de la base de données.",
		technologies: ["HTML", "CSS", "JavaScript", "VueJS", "Bootstrap", "API", "NodeJS", "Express", "SQL"],
		filter: ["Tout"],
		liens: [{ name: "GitHub", value: "https://github.com/ArthurBlanc/Groupomania" }],
		image: {
			name: "014.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Mai 2021",
	},
	{
		id: 15,
		nom: "GameOn",
		slug: "gameon",
		description:
			"GameOn est un projet réalisé dans le cadre de ma formation de Développeur Front-end chez OpenClassrooms. J'ai travaillé à partir d'une maquette fournie pour créer une landing page avec HTML, CSS et JavaScript. L'objectif était de permettre aux utilisateurs de s'inscrire à un concours national de jeux vidéo. Le résultat final est une page web claire et attrayante qui permet aux utilisateurs de remplir un formulaire d'inscription. J'ai ajouté du code JavaScript pour rendre le formulaire de contact pleinement fonctionnel, en validant les champs du formulaire côté client avant l'envoi des données au serveur. Ce projet m'a permis de renforcer mes compétences en développement front-end et d'acquérir de l'expérience dans la création de pages web fonctionnelles et esthétiques.",
		technologies: ["HTML", "CSS", "JavaScript"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/GameOn/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/GameOn" },
		],
		image: {
			name: "015.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Août 2021",
	},
	{
		id: 16,
		nom: "FishEye",
		slug: "fisheye",
		description:
			"FishEye est un projet réalisé dans le cadre de ma formation de Développeur Front-end chez OpenClassrooms. J'ai travaillé à partir d'une maquette fournie pour créer un site web pour une plateforme de photographes. Le site web est responsive et accessible, avec des fonctionnalités avancées telles que des boutons de filtre pour trier les photographes, des formulaires de contact pour communiquer avec les photographes, une galerie de photos avec un tri par tag et des sliders pour chaque photographe. J'ai utilisé les technologies HTML, CSS, Sass, JavaScript, jQuery et Bootstrap pour créer le site web. Un gros effort a été fait pour que le site soit accessible, avec notamment une navigation au clavier ou avec un lecteur d'écran. Ce projet m'a permis de renforcer mes compétences en développement front-end, en particulier en matière de responsive design et d'accessibilité.",
		technologies: ["HTML", "CSS", "Sass", "JavaScript", "jQuery", "Bootstrap"],
		filter: ["Tout"],
		github: "https://github.com/ArthurBlanc/FishEye",
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/FishEye/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/FishEye" },
		],
		image: {
			name: "016.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Novembre 2021",
	},
	{
		id: 17,
		nom: "Simple rock-paper-scissors",
		slug: "simple-rock-paper-scissors",
		description:
			"Simple rock-paper-scissors est un projet de jeu que j'ai créé en utilisant les technologies HTML, CSS, JavaScript et Bootstrap. L'objectif de ce projet était de créer un jeu classique de pierre-feuille-ciseaux en utilisant du code JavaScript pour gérer la logique de jeu et du CSS pour le style visuel. Le résultat final est un jeu interactif et divertissant qui peut être joué dans un navigateur web. Ce projet m'a permis de renforcer mes compétences en développement front-end et en particulier en matière de manipulation d'événements en JavaScript.",
		technologies: ["HTML", "CSS", "JavaScript", "Bootstrap"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/simple_rock-paper-scissors/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/simple_rock-paper-scissors" },
		],
		image: {
			name: "017.jpg",
			alt: "",
		},
		client: {
			nom: "Arthur BLANC",
		},
		date: "Décembre 2021",
	},

	{
		id: 18,
		nom: "Les petits plats",
		slug: "les-petits-plats",
		description:
			"Les petits plats est un projet que j'ai réalisé dans le cadre de ma formation de Développeur Front-end chez OpenClassrooms. L'objectif était de créer une page web responsive pour un site de recettes de cuisine, en utilisant une maquette fournie comme guide. Pour faciliter la recherche de recettes, j'ai développé un algorithme en JavaScript. J'ai utilisé les technologies HTML, CSS, Sass, JavaScript et Bootstrap pour concevoir une page web esthétique, fonctionnelle et facile à naviguer. Ce projet m'a permis de renforcer mes compétences en développement front-end, en particulier en algorithmie pour la recherche de recettes.",
		technologies: ["HTML", "CSS", "Sass", "JavaScript", "Bootstrap"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/LesPetitsPlats/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/LesPetitsPlats" },
		],
		image: {
			name: "018.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Janvier 2022",
	},
	{
		id: 19,
		nom: "Billed",
		slug: "billed",
		description:
			"Billed est un projet que j'ai réalisé dans le cadre de ma formation de Développeur Front-end chez OpenClassrooms. Le projet consistait à déboguer une application existante en utilisant les technologies JavaScript et Jest pour la réalisation de tests unitaires. J'ai également utilisé des API pour interagir avec l'application. Ce projet m'a permis de renforcer mes compétences en développement front-end, en particulier en matière de débogage et de tests unitaires.",
		technologies: ["JavaScript", "Jest", "API"],
		filter: ["Tout"],
		liens: [{ name: "GitHub", value: "https://github.com/ArthurBlanc/Billed" }],
		image: {
			name: "019.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Mars 2022",
	},
	{
		id: 20,
		nom: "Learn@Home",
		slug: "learn@home",
		description:
			"Learn@Home est un projet que j'ai réalisé dans le cadre de ma formation de Développeur Front-end chez OpenClassrooms. L'objectif était de créer des maquettes sous Figma pour une application web de soutien scolaire, ainsi que des user stories pour définir les besoins des utilisateurs. J'ai utilisé Figma et Kanban pour réaliser ce projet. Le résultat final est un ensemble de maquettes claires et précises, ainsi qu'un ensemble de user stories détaillées qui ont permis de mieux comprendre les besoins des utilisateurs. Ce projet m'a permis de renforcer mes compétences en conception d'interface utilisateur et en méthodologie de développement de projet.",
		technologies: ["Figma", "Kanban"],
		filter: ["Tout"],
		liens: [{ name: "Figma", value: "https://www.figma.com/file/q7bNzThsz3iX7pPCCeKCpV/P10---Lear%40Home?node-id=19911%3A1960" }],
		image: {
			name: "020.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Mars 2022",
	},
	{
		id: 21,
		nom: "Kasa ",
		slug: "kasa",
		description:
			"Kasa est une application web que j'ai développée dans le cadre de ma formation de Développeur Front-end chez OpenClassrooms. À partir d'une maquette fournie, j'ai réalisé l'intégration et le développement de cette plateforme de location d'appartements en utilisant les technologies HTML, CSS, JavaScript, ainsi que les bibliothèques React et React Router pour créer des interfaces utilisateur dynamiques et interactives. J'ai également utilisé Sass pour faciliter la gestion des styles et une API pour récupérer les données des appartements à afficher sur l'application. J'ai notamment créé un composant dropdown pour les descriptions et les équipements des appartements, ainsi qu'un slider pour l'affichage des images. Le résultat final est une application web esthétique et fonctionnelle, qui m'a permis de mieux comprendre les concepts clés de React et de développer mes compétences en développement front-end.",
		technologies: ["HTML", "CSS", "JavaScript", "React", "React Router", "API", "Sass"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/Kasa/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/Kasa" },
		],
		image: {
			name: "021.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Mai 2022",
	},
	{
		id: 22,
		nom: "SportSee",
		slug: "sportsee",
		description:
			"SportSee est un projet que j'ai réalisé dans le cadre de ma formation de Développeur Front-end chez OpenClassrooms. J'ai intégré et développé un tableau de bord d'analyse pour une application web sportive en utilisant les technologies HTML, CSS, JavaScript, React, React Router, Sass, et l'outil de visualisation de données Recharts. J'ai également utilisé une API pour récupérer les données nécessaires à l'affichage des graphiques. Le résultat final est un tableau de bord fonctionnel et esthétique, permettant aux utilisateurs d'analyser leurs performances sportives de manière détaillée. Ce projet m'a permis de renforcer mes compétences en développement front-end, en particulier en matière de visualisation de données et de manipulation d'API.",
		technologies: ["HTML", "CSS", "JavaScript", "React", "React Router", "API", "Sass", "Recharts"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/SportSee/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/SportSee" },
		],
		image: {
			name: "022.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Mai 2022",
	},
	{
		id: 23,
		nom: "Argent Bank",
		slug: "argent-bank",
		description:
			"Argent Bank est un projet que j'ai réalisé dans le cadre de ma formation de Développeur Front-end chez OpenClassrooms. À partir d'une maquette fournie, j'ai développé une application web avec authentification des utilisateurs en utilisant les technologies HTML, CSS, JavaScript, React, React Router, Redux, Sass, une API pour récupérer les données et MongoDB pour stocker les informations des utilisateurs. Le résultat final est une application web sécurisée permettant aux utilisateurs de se connecter à leur compte et de visualiser leurs transactions bancaires. Ce projet m'a permis de renforcer mes compétences en développement front-end et de découvrir l'architecture Redux pour la gestion d'état dans les applications web.",
		technologies: ["HTML", "CSS", "JavaScript", "React", "React Router", "Redux", "API", "MongoDB", "Sass"],
		filter: ["Tout"],
		liens: [{ name: "GitHub", value: "https://github.com/ArthurBlanc/ArgentBank" }],
		image: {
			name: "023.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Juillet 2022",
	},
	{
		id: 24,
		nom: "HRnet",
		slug: "hrnet",
		description:
			"HRnet est un projet que j'ai réalisé dans le cadre de ma formation de Développeur Front-end chez OpenClassrooms. J'ai converti quatre librairies JQuery vers React en utilisant les technologies HTML, CSS, JavaScript, React, React Router, Redux, Sass et publié une de ces librairie sur npmjs.com. Cette conversion m'a permis de découvrir les différences de fonctionnement entre les deux bibliothèques et de renforcer mes compétences en développement front-end. Ce projet m'a également permis d'approfondir mes connaissances en matière de publication de packages sur npmjs.com.",
		technologies: ["HTML", "CSS", "JavaScript", "React", "React Router", "Redux", "Sass", "jQuery"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/HRnet-live/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/HRnet" },
		],
		image: {
			name: "024.jpg",
			alt: "",
		},
		client: {
			nom: "OpenClassrooms",
			lien: "https://openclassrooms.com/fr/",
		},
		date: "Octobre 2022",
	},
	{
		id: 25,
		nom: "Parking Montpellier",
		slug: "parking-montpellier",
		description:
			"Parking Montpellier est un projet personnel que j'ai développé en utilisant les technologies HTML, CSS, JavaScript, React, Sass, et une API pour récupérer les informations sur les parkings de la ville de Montpellier. J'ai également utilisé Node.js pour créer un corsproxy permettant de récupérer les données de l'API. Le résultat final est une application web pratique et fonctionnelle, permettant aux utilisateurs de visualiser les informations en temps réel sur les parkings de la ville de Montpellier. Ce projet m'a permis de consolider mes compétences en développement front-end et de mettre en pratique mes connaissances en matière de gestion d'API",
		technologies: ["HTML", "CSS", "JavaScript", "React", "Sass", "API", "Node.js"],
		filter: ["Tout"],
		liens: [
			{ name: "Site Live", value: "https://arthurblanc.github.io/Parking-Montpellier/" },
			{ name: "GitHub", value: "https://github.com/ArthurBlanc/Parking-Montpellier" },
		],
		image: {
			name: "025.jpg",
			alt: "",
		},
		client: {
			nom: "Arthur BLANC",
		},
		date: "Janvier 2023",
	},
]
