import React from "react"
import ReactDOM from "react-dom/client"

import Router from "./Router"

import "./main.scss"

const root = ReactDOM.createRoot(document.getElementById("root"))
// Rendering the Provider component with the store and the Router component. */
root.render(
	<React.StrictMode>
		<Router />
	</React.StrictMode>
)
