import React from "react"
import { BrowserRouter, Routes, Route, useNavigate, useParams } from "react-router-dom"

import Home from "./pages/Home"
import CV from "./pages/CV"
import Portfolio from "./pages/Portfolio"
import Contact from "./pages/Contact"
import MentionLegal from "./pages/MentionLegal"
import Error404 from "./pages/404"
import ProjectDetail from "./pages/ProjectDetail"

import Nav from "./components/Nav"
import Footer from "./components/Footer"
import ContactezMoi from "./components/ContactezMoi"

import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { listeProjets } from "./utils/ListeProjet.js"

function ScrollToTop() {
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}

function RedirectToExternalUrl({ path = "/", project }) {
	const { projetId } = useParams()

	const projectArray = projetId ? project.find((el) => el.id === +projetId) : null

	const newPage = projectArray !== null ? `https://arthurblanc.dev/projet/${projectArray.slug}` : `https://arthurblanc.dev${path}`

	useEffect(() => {
		window.location.status = 301
		window.location.replace(newPage)
	}, [newPage])

	return <div>Redirection vers la page : {newPage}</div>
}

/**
 * Router to render the 2 pages of the application
 *
 * @category Router
 * @component
 * @returns { React.Component } A React component
 */
function Router() {
	return (
		<React.StrictMode>
			<BrowserRouter>
				{/*<ScrollToTop />*/}
				{/*<Nav />*/}
				<Routes>
					{/*<Route exact path="/" element={<Home />} />*/}
					<Route exact path="/" element={<RedirectToExternalUrl path="/" />} />
					{/*<Route exact path="/index.html" element={<Home />} />*/}
					<Route exact path="/index.html" element={<RedirectToExternalUrl path="/" />} />
					{/*<Route exact path="/cv" element={<CV />} />*/}
					<Route exact path="/cv" element={<RedirectToExternalUrl path="/cv" />} />
					{/*<Route exact path="/portfolio" element={<Portfolio />} />*/}
					<Route exact path="/portfolio" element={<RedirectToExternalUrl path="/portfolio" />} />
					{/*<Route exact path="/contact" element={<Contact />} />*/}
					<Route exact path="/contact" element={<RedirectToExternalUrl path="/contact" />} />
					{/*<Route exact path = "/mentions-legales" element = {<MentionLegal />} />*/}
					<Route exact path="/mentions-legales" element={<RedirectToExternalUrl path="/mentions-legales" />} />
					{/*<Route exact path="/projet/:projetId" element={<ProjectDetail />} />*/}
					<Route exact path="/projet/:projetId" element={<RedirectToExternalUrl project={listeProjets} />} />
					{/*<Route path="*" element={<Error404 />} />*/}
					<Route path="*" element={<RedirectToExternalUrl path="/404" />} />
				</Routes>
				{/*<ContactezMoi />*/}
				{/*<Footer />*/}
			</BrowserRouter>
		</React.StrictMode>
	)
}

export default Router
