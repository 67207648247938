import { useEffect } from "react"
import "./styles.scss"

import PortfolioCard from "../../components/PortfolioCard"
import { listeProjets } from "../../utils/ListeProjet"

function Portfolio() {
	const sortedList = listeProjets.sort((a, b) => b.id - a.id)

	useEffect(() => {
		document.title = "Portfolio - Arthur BLANC : Développeur Front-end"
		document.querySelector("meta[name='description']").setAttribute("content", "Vous trouverez ici des projets Web sur lesquels j'ai travaillé, ainsi que leurs descriptions.")
	}, [])

	return (
		<main className="portfolio-page">
			<div className="portfolio-hero-banner">
				<div className="portfolio-hero-text">
					<h1>Portfolio</h1>
					<p>Vous trouverez ici des projets Web sur lesquels j'ai travaillé, ainsi que leurs descriptions respectives.</p>
					<p>
						<a href="https://github.com/ArthurBlanc" target="_blank" rel="noreferrer">
							Github
						</a>
					</p>
				</div>
			</div>
			<section className="portfolio-section">
				<div className="container">
					<div className="portfolio-card-wrapper">
						{sortedList.map((projet) => (
							<PortfolioCard key={projet.id} id={projet.id} title={projet.nom} description={projet.description} image={projet.image.name} imageAlt={projet.image.alt} links={projet.liens} />
						))}
					</div>
				</div>
			</section>
		</main>
	)
}

export default Portfolio
